import { type App } from "vue"
import { usePageStore } from "#school/store/page"

export default function(app: App) {
  const router = app.config.globalProperties.router

  router.beforeEach((to: any, from: any) => {
    const page = usePageStore(app.config.globalProperties.$pinia)
    page.clearError()
    page.clear()
  })
}
